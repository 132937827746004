import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { patterns } from 'src/app/shared/helper/email.helper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-esqueceu-senha',
  templateUrl: './esqueceu-senha.component.html',
  styleUrls: ['./esqueceu-senha.component.scss']
})
export class EsqueceuSenhaComponent implements OnInit {

  senhaForm: FormGroup

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private loginService: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
    this.senhaForm = this.fb.group({
       email: this.fb.control('', [Validators.required, Validators.pattern(patterns.email)]),
     })
  }

  postEmail(){
    if(this.senhaForm.valid){
      this.loginService.esqueceuSenha(this.senhaForm.value)
          .subscribe(response => {
            this.toastrService.success("A senha foi enviada para seu e-mail !")
            this.router.navigate(['/'])
          },
          error=>{
            this.toastrService.error("Usuário não existe no sistema !")
            this.senhaForm.reset()
          })
    }else{
      this.toastrService.error("Preencha os campos corretamente")
      this.senhaForm.reset()
    }
  }

}
