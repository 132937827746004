<div class="background">
  <form [formGroup]="senhaForm">
    <div class="container-fluid">
        <div class="d-flex justify-content-center align-items-center h-100">
          <div class="col-md-6 d-flex align-items-center">
              <div class="card p-5">
                  <div class="d-flex justify-content-start align-items-center mb-3">
                      <a routerLink="/" class="mr-1"> <img width="30px" src="assets/icons/arrow.png"> Voltar </a>
                  </div>
                  <h5>Enviaremos a senha via e-mail</h5>
                  <div class="d-flex justify-content-center">
                      <div class="col-md-8">
                          <img class="w-100 mt-3 mb-3" src="assets/icons/send_mail.png">
                          <input class="form-control mt-3" formControlName="email" placeholder="E-mail">
                          <button (click)="postEmail()" class="btn btn-success float-right mt-3">Enviar</button>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
    </form>
  </div>
