import { LoginService } from './../../services/login.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { patterns } from 'src/app/shared/helper/email.helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginform: FormGroup
  
  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private toastrService: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loginform = this.fb.group({
       email: this.fb.control('', [Validators.required, Validators.pattern(patterns.email)]),
       password: this.fb.control('', [Validators.required])
     })
  }

  login(){
    this.loginService.login(this.email, this.password)
        .subscribe(response => {
          this.toastrService.success("Entrando")
          if(this.loginService.isUser){
            this.router.navigate(['/usuario'])
          }else{
            this.router.navigate(['/admin'])
          }
        },
        error => {
          this.toastrService.error("E-mail ou Senha inválido")
          this.loginform.reset()
        })
  }

  get email(){
    return this.loginform.get('email').value
  }

  get password(){
    return this.loginform.get('password').value
  }
}
