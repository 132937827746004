import { AdminGuard } from './guards/admin.guard';
import { StorageService } from './../services/localsorage.service';
import { UserGuard } from './guards/user.guard';
import { LoginGuard } from './guards/login.guard';
import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginService } from '../services/login.service';
import { BrowserModule } from '@angular/platform-browser';
import { AuthInterceptor } from './auth.interceptor';
import { EsqueceuSenhaComponent } from './esqueceu-senha/esqueceu-senha.component';

@NgModule({
  declarations: [LoginComponent, EsqueceuSenhaComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
  ],
  exports:[
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
  return {
    ngModule: CoreModule,
    providers: [
      LoginService,
      AdminGuard,
      LoginGuard,
      UserGuard,
      StorageService,
      {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi:true},
    ]
  }
} 
}
