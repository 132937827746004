import { StorageService } from './localsorage.service';
import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '../shared/models/user.model';
import { Observable } from 'rxjs';
import { API } from '../app.api';
import { tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  
  helper = new JwtHelperService();
  private _user: User;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localstorageService: StorageService
  ) {}

  
  isLoggedIn(): boolean {
    if(this.localstorageService.getLocalStorage("user_chronos")){
      return true
    }
  }

  login(email: string, password: string): Observable<any>{
    return this.http.post<User>(`${API}/auth/login`, { email: email, password: password })
        .pipe(tap(user =>{ 
          this._user = user
          this.initUser(user)
        }))
  }

  esqueceuSenha(email:string){
    return this.http.post(`${API}/auth/reset-password`, email)
  }

  logout() {
    this.localstorageService.removeLocalStorage("user_chronos")
    delete this._user;
    this.router.navigate(['/'])
  }

  /**
   * Inicializa as informações do usuário logado.
   * @param response
   */
  private initUser(response) {
    this._user = response;
    this.localstorageService.setLocalStorage("user_chronos", this._user)
  }


  private isValid() {
    return !!this.user;
  }

  /**
   * Retorna o usuário;
   */
  get user() {
    if (this._user === undefined && this.localstorageService.getLocalStorage("user_chronos") != null) {
      this._user = this.localstorageService.getLocalStorage("user_chronos");
    }
    return this._user;
  }

  getTokenExpired(){
      return this.helper.isTokenExpired(this.token)
  }

  get isAdmin(){
    return this.isValid() && this.user.user.role.slug == 'admin'
  }

  get isUser(){
    return this.isValid() && this.user.user.role.slug == 'user'
  }
  
  get token() {
    return this.isValid() && this.user.token;
  }
    
}
