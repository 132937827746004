<div class="background">
  <form [formGroup]="loginform">
  <div class="container-fluid">
    <div class="row h-100">
      <div class="col-md-12 text-center">
        <div class="d-flex justify-content-center align-items-center h-100">
          <div class="card p-5">
              <h5>Chronos Engenharia</h5>
              <input class="form-control mt-2" formControlName="email" placeholder="E-mail">
              <input class="form-control mt-2" type="password"  formControlName="password" placeholder="Senha">
              <a class="mt-1 mb-1" routerLink="/esqueceu-senha" >Esqueceu a sua senha?</a>
              <button (click)="login()" class="btn btn-success mt-2">Login</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
  <footer>
    <div class="row">
        <div class="col-md-12 col-12">
            <div class="d-flex justify-content-center align-items-center h-100">
                <span class="mr-1">Desenvolvido por:</span>
                <a title="https://andrehenriiques.github.io/" href="https://andrehenriiques.github.io/"
                class="d-flex justify-content-center  align-items-center  mr-2 h-100">
                    <img class="mr-1" height="20px" src="assets/footer_develop.png">
                    <div class="ass_andre h-100">
                        <p>André Henriques</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
  </footer>
</div>
