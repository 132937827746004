import { LoginService } from 'src/app/services/login.service';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';


@Injectable()
export class LoginGuard implements CanActivate {

    constructor(
        private router: Router,
        private loginService: LoginService
    ) {};

    canActivate(): boolean {
        let acesso = false;

        if (this.loginService.isLoggedIn()) {
            if(this.loginService.isUser){
                this.router.navigate(["/usuario"]);
              }else if(this.loginService.isAdmin){
                  this.router.navigate(["/admin"])
              }
              return acesso
        }else{
            return !acesso
        }
    };
}