import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';

@Injectable()
export class UserGuard implements CanActivate {
    
    constructor(
        private loginService: LoginService, 
        private router: Router
        ) { };

    canActivate(): boolean {
        let usuario = this.loginService.isUser;
        if(usuario){
            return true;
        }
        if (!usuario) {
            this.router.navigate(['/']);
        }
        return false
    };
}