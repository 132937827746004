import { LoginService } from 'src/app/services/login.service';

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';


@Injectable()
export class AdminGuard implements CanActivate {
    
    constructor(
        private loginService: LoginService, 
        private router: Router
        ) { };

    canActivate(): boolean {
        let admin = this.loginService.isAdmin;
        if(admin){
            return true;
        }
        if (!admin) {
            this.router.navigate(['/']);
        }
        return false
    };
}