import { EsqueceuSenhaComponent } from './core/esqueceu-senha/esqueceu-senha.component';
import { LoginGuard } from './core/guards/login.guard';
import { LoginComponent } from './core/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path:'', redirectTo: 'login', pathMatch:'full'},
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  { path: 'esqueceu-senha', component: EsqueceuSenhaComponent, canActivate: [LoginGuard] },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
  { path: 'usuario', loadChildren: () => import('./user/user.module').then(m => m.UserModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
